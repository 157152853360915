import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en/translation.json';
import arTranslation from './locales/ar/translation.json';


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: ["en", "ar"],
        resources: {
            en: {
                translation: enTranslation
            },
            ar:{
                translation: arTranslation
            }
        }

        // debug: process.env.NODE_ENV === "development",

    });


export default i18n;