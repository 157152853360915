
import bg from '../assets/img/bg.jpg';
import bg2 from '../assets/img/bg_2.jpg';

// import required modules
import { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

import React from "react";



// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";




export function Header() {
  return (
    <>
      <Swiper
        effect='fade'
        spaceBetween={100}
        slidesPerView={1}
        centeredSlides={true}
        speed={2000}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        keyboard={{
          enabled: true,
        }}
        modules={[EffectFade, Autoplay]}
        className='swiper-con'
      >
        <SwiperSlide>
          <img src={bg} alt='Sila Technology' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bg2} alt='سيلا للتيكنولوجيا' loading='lazy' />
        </SwiperSlide>
        <div className='overlay'> </div>
      </Swiper>
    </>
  );
}
