import { useTranslation } from 'react-i18next';

export const Features = (props) => {
  const { t } = useTranslation();
  return (
    <div id='features' className='section text-center'>
      <div className='container'>
        <div className='col-md-10 m-auto section-title mb-5'>
          <h2>
            {t('features.title')}
          </h2>
        </div>
        <div className='row' data-aos="fade-down" data-aos-duration="1000">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.title}-${i}`} className='col-12 col-md-8 col-lg-4 mx-auto'>
                <div className='card-custom'>
                  <i className={d.icon}></i>
                  <h3> {t(d.title)}</h3>
                  <p>{t(d.text)}</p>
                </div>
              </div>
            ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
