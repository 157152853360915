import { useTranslation } from 'react-i18next';

export const About = (props) => {
  const { t } = useTranslation();
  return (
    <div id='about' className="section about" >
      <div className='container'>
        <div className='about-main' data-aos="fade-left" data-aos-delay="400" data-aos-duration="600">
          <div>
            <img src={require('../assets/img/bg_2.jpg')}
              className='img-responsive' alt='About Us'
              loading='lazy'
              height={300}
              width={'100%'}
            />
          </div>
          <div className='about-text'>
            <h2>
              {t('about.title')}
            </h2>
            <p>{t("about.text")}</p>

            <p>{t("about.text_2")}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
