import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react'


const SmallNav = ({ showBG }) => {
  const { t, i18n } = useTranslation();
  const offcanvasDir = i18n.language == 'ar' ? "offcanvas-end" : "offcanvas-start";

  return (<nav className={`navbar navbar-sm ${showBG ? "nav__bg" : ""}`}>
    <div className="container-fluid">
      <a className="navbar-brand" href="#">
        <img src={require("../assets/img/logo_sm.png")}
          className="logo-image"
          alt='logo'
          width={182}
          height={60} />
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
        <i className="fas fa-bars"></i>
      </button>
      <div className={["offcanvas", offcanvasDir].join(' ')} tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div className="offcanvas-header">
          <img src={require("../assets/img/logo_sm.png")} className="logo-image" />
          <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav justify-content-end flex-grow-1 ">


            <li className="nav-item">
              <a className="nav-link page-scroll" data-scroll aria-current="page" href="#">
                {t("nav.home")}
              </a>

            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" data-scroll href="#features">
                {t("nav.features")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-scroll href="#about">
                {t("nav.aboutUs")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" data-scroll href="#services">
                {t("nav.services")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" data-scroll href="#portfolio">
                {t("nav.expo")}
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link page-scroll" data-scroll href="#contact">
                {t("nav.contactUs")}
              </a>
            </li>
            <li className="nav-item ">
              <select className="" defaultValue={i18n.language} onChange={e => {
                i18n.changeLanguage(e.target.value)

              }}>
                <option value={'ar'}>
                  {t('nav.lang.ar')}
                </option>
                <option value={'en'}>
                  {t('nav.lang.en')}
                </option>
              </select>
            </li>


          </ul>
        </div>
      </div>
    </div>
  </nav>
  )
}


const LargeNav = ({ showBG }) => {
  const { t, i18n } = useTranslation();
  return (
    <nav id='main' className={`navbar navbar-lg navbar-expand-lg  navbar-light ${showBG ? "nav__bg" : ""}`}>
      <div className="container-lg">
        <a className="navbar-brand" href='/'>
          <img src={require("../assets/img/logo_sm.png")}
            className="logo-image"
            alt='logo'
            width={182}
            height={60} />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav  mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link page-scroll" data-scroll aria-current="page" href="#">
                {t("nav.home")}
              </a>

            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" data-scroll href="#features">
                {t("nav.features")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-scroll href="#about">
                {t("nav.aboutUs")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" data-scroll href="#services">
                {t("nav.services")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link page-scroll" data-scroll href="#portfolio">
                {t("nav.expo")}
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link page-scroll" data-scroll href="#contact">
                {t("nav.contactUs")}
              </a>
            </li>
            <li className="nav-item ">
              <select className="" defaultValue={i18n.language} onChange={e => {
                i18n.changeLanguage(e.target.value)

              }}>
                <option value={'ar'}>
                  {t('nav.lang.ar')}
                </option>
                <option value={'en'}>
                  {t('nav.lang.en')}
                </option>
              </select>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  )
}


export const Navigation = (props) => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [showBG, setShowBG] = useState(false);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setShowBG(true);
      } else {
        setShowBG(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return screenSize.width < 992 ? <SmallNav showBG={showBG} /> : <LargeNav showBG={showBG} />;

}


