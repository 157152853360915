export const Footer = (props) => {

  return (
    <div>
      <div id='footer' className="footer">
        <div className='container text-center'>
          <p>
            &copy; 2023 Sila Technology
            <br />
            This site created by
            <a href="https://www.facebook.com/people/Libya-Digital-Wizard/100090640029673/"> LDW Team</a>
          </p>
        </div>
      </div>
    </div>
  )
}
