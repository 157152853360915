import { useEffect } from 'react'
import Home from './components/Home';


import AOS from 'aos';
import 'aos/dist/aos.css';

import 'react-toastify/dist/ReactToastify.min.css';
import './assets/css/app.css';
import './assets/css/app_ar.css';


const App = () => {
  useEffect(() => {
    AOS.init({
      delay: 200,
      duration: 500,
      once: true,
    });
  }, [])

  return <Home />

}

export default App;
