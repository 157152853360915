import { useState } from 'react';
import axios from './axios/axios';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

const initialState = {
  name: '',
  email: '',
  message: '',
}


export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const data = {
      email: formData.get('email'),
      name: formData.get('name'),
      content: formData.get('message')
    }
    e.target.reset();
    toast.promise(
      axios.post('emails/add/', data),
      {
        pending: t('contact.pending'),
        success: `${t('contact.success')} 👌`,
        error: `${t('contact.error')} 🤯`
      }
    )
    clearState();

  }
  return (
    <div>

      <div id='contact' className="contact section">
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 col-12'>
              <div className='section-title'>
                <h2>
                  {t('contact.form.title')}
                </h2>
                <p>
                  {t('contact.form.text')}
                </p>
              </div>
              <form name='sentMessage' validate={'true'} onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6 mb-3'>
                    <div className='form-group '>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder={t('contact.form.fields.name')}
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6 mb-3'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder={t('contact.form.fields.email')}
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='5'
                    placeholder={t('contact.form.fields.message')}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit'
                  className='btn btn-custom btn-lg'>
                  {t('contact.form.send')}
                </button>
              </form>

            </div>
            <div className='col-md-1'></div>
            <div className='col-md-3 col-12 contact-info'>
              <div className='contact-item'>
                <h3>
                  {t('contact.info.title')}
                </h3>
                <p>
                  <span>
                    <i className='fa fa-map-marker'></i>
                    {t('contact.info.address.label')}
                  </span>
                  {t('contact.info.address.value')}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-phone'></i>
                    {t('contact.info.phone.label')}
                  </span>
                  {t('contact.info.phone.value')}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-envelope-o'></i>
                    {t('contact.info.email.label')}
                  </span>
                  {t('contact.info.email.value')}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className="fab fa-facebook"></i>
                    {t('contact.info.facebook.label')}
                  </span>
                  <a href='https://www.facebook.com/silatech.ly'>
                    {t('contact.info.facebook.value')}
                  </a>
                </p>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}
