import React, { useState, useCallback, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";

import { useTranslation } from 'react-i18next';

import axios from './axios/axios';

export const Gallery = props => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);
  const { t } = useTranslation();


  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    const featchImages = async () => {
      const res = await axios.get("/core/gallery/");
      const imageArr = []
      for (let i = 0; i < res.data.length; i++) {
        imageArr.push(res.data[i].image);
      }
      setImages(imageArr);
    }
    featchImages();
  }, [])

  return !images.length < 1 ? (
    <div id="portfolio" className={`text-center ${images ? '' : "d-none"}`}>
      <div className="container">
        <div className="section-title">
          <h2>
            {t('gallery.title')}
          </h2>

        </div>

        <div className="portfolio-items">
          {images.map((image, index) => (
            <div key={index} onClick={() => openImageViewer(index)}
              className="col-12 col-md-6 col-lg-4">
              <div className="portfolio-item cursor">
                <div className="hover-text">
                </div>
                <img src={image} className="img-responsive" alt="Project Title" loading="lazy" />
              </div>
            </div>
          ))}
        </div>

        {isViewerOpen && (
          <ImageViewer
            src={images}
            backgroundStyle={{ zIndex: 99999 }}
            currentIndex={currentImage}
            onClose={closeImageViewer}
          />
        )}

      </div>
    </div>
  ) : null;
};
