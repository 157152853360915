import { useTranslation } from 'react-i18next';

export const Services = (props) => {
  const { t } = useTranslation();

  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>
            {t('services.title')}
          </h2>
          <p>
            {t('services.text')}
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-lg-4 col-md-6'>
                {' '}
                <div className='service-desc'>
                  <i className={d.icon}></i>
                  <h3>{t(d.name)}</h3>

                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
