import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigation } from './navigation';
import { Header } from './header'
import { Features } from './features'
import { About } from './about'
import { Services } from './services'
import { Gallery } from './gallery'
import { Contact } from './contact';

import { Footer } from './footer'
import { ToastContainer } from 'react-toastify';


import 'react-toastify/dist/ReactToastify.min.css';



import JsonData from '../data/data.json'
import SmoothScroll from 'smooth-scroll';

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});


const Home = () => {
    const { i18n } = useTranslation();
    const [landingPageData, setLandingPageData] = useState({})
    useEffect(() => {
        setLandingPageData(JsonData)
    }, [])

    return (
        <div className={i18n.language == 'ar' ? 'ar' : null}>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Gallery />
            <Contact data={landingPageData.Contact} />
            <Footer />
            <ToastContainer position="bottom-left"
                autoClose={5000}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <a className='whatsapp-icon'
                href='https://wa.me/+218911338828'
                aria-label="تواصل معنا على واتس أب">
                <i className="fab fa-whatsapp"></i>
            </a>
        </div>
    )
}

export default Home;
